import { definePlugin } from '@dundle/utils/nuxt';
import { resolveWhenIdle } from '@dundle/utils/promise';

export default definePlugin(({ app }, inject) => {
    const _sift = (window._sift = window._sift || []);

    if (process.env.NODE_ENV === 'production') {
        window.onNuxtReady(async () => {
            await resolveWhenIdle({
                name: 'sift.js',
                priority: 4,
                maxTimeout: 5000,
            });

            const e = document.createElement('script');
            e.src = 'https://cdn.siftscience.com/s.js';
            e.defer = true;
            document.body.appendChild(e);
        });
    }

    app.$session.requestLoadedCallback(() => {
        const siftAccount = '529ed174a0';
        const userId = '';

        const sessionId = app.$session.id;

        _sift.push(['_setAccount', siftAccount]);
        _sift.push(['_setUserId', userId]);
        _sift.push(['_setSessionId', sessionId]);
        _sift.push(['_trackPageview']); // First page view

        // From now on (!important) we will track next page views:
        app.router.afterEach((to, from) => {
            requestIdleCallback(() => {
                _sift.push(['_trackPageview']);
            });
        });
    });
});
