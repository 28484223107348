import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, store }, inject) => {
    inject(
        'session',
        new Vue({
            data() {
                let resolver;

                const isLoadedPromise = new Promise(resolve => {
                    resolver = () => resolve();
                });

                return {
                    onLoadedlisteners: [resolver],
                    isLoadedPromise,
                    idFromCookie: '',
                };
            },

            computed: {
                hasCookie() {
                    return !!this.idFromCookie;
                },

                isLoaded() {
                    return store.getters['session/isLoaded'];
                },

                id() {
                    return store.getters['session/id'];
                },
            },

            watch: {
                isLoaded() {
                    this.onLoadedlisteners.forEach(listener => listener());
                },
            },

            created() {
                if (process.client) {
                    const sessionId = app.$cookies.get('dundle.session.id');
                    if (sessionId) {
                        app.$cookies.remove('dundle.session.id');
                        this.syncCookie(sessionId);
                    }
                    this.idFromCookie = sessionId;
                }
            },

            methods: {
                requestLoadedCallback(func) {
                    this.onLoadedlisteners.push(func);
                },

                async waitUntilIsLoaded() {
                    await this.isLoadedPromise;
                },
                syncCookie(sessionId) {
                    if (this.idFromCookie !== sessionId) {
                        app.$cookies.set('dundle.session.id', sessionId, {
                            maxAge: 60 * 60 * 24 * 7 * 52,
                            path: '/',
                        });
                    }
                },
            },
        })
    );
});
