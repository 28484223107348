export function parseHostname(url) {
    try {
        return new URL(url).hostname;
    } catch {
        return null;
    }
}

export function parseUTM(query, prevUTM) {
    // Based upon: https://support.google.com/analytics/answer/2731565?hl=en#zippy=%2Cin-this-article

    const utm = {
        source: query.utm_source,
        medium: query.utm_medium,
        campaign: query.utm_campaign,
        term: query.utm_term,
        content: query.utm_content,
        referrer: document.referrer,
    };

    // If source and medium are set, use the utm params:
    if (utm.source && utm.medium) {
        return utm;
    }

    // Try to find the correct source / medium:
    const organicSources = ['google', 'bing', 'yahoo', 'yandex', 'ask'];
    const socialSources = ['facebook', 'instagram', 'snapchat', 'twitter', 'youtube'];

    const referrer = parseHostname(utm.referrer) || '';

    const isAffiliateTraffic = typeof query.ref_id !== 'undefined';
    const isOrganicTraffic = typeof referrer === 'string' && referrer.length > 0 && typeof organicSources.find(source => referrer.includes(source)) === 'string';
    const isSocialTraffic = typeof referrer === 'string' && referrer.length > 0 && typeof socialSources.find(source => referrer.includes(source)) === 'string';
    const isAdwordsTraffic = typeof query.gclid !== 'undefined';
    const isReferringTraffic = typeof referrer !== 'undefined' ? typeof referrer === 'string' && referrer.length > 0 : false;

    if (isAffiliateTraffic) {
        return {
            ...utm,
            source: 'affiliate',
            medium: 'link',
            campaign: query.ref_id,
        };
    } else if (isAdwordsTraffic) {
        return {
            ...utm,
            source: 'google',
            medium: 'cpc',
        };
    } else if (isReferringTraffic) {
        // Organic is a sub variant of referral:
        if (isOrganicTraffic) {
            const source = organicSources.find(source => referrer.includes(source));

            return {
                ...utm,
                source,
                medium: 'organic',
            };
        }

        // Social is a sub variant of referral:
        if (isSocialTraffic) {
            const source = socialSources.find(source => referrer.includes(source));

            return {
                ...utm,
                source,
                medium: 'social',
            };
        }

        return {
            ...utm,
            source: referrer,
            medium: 'referral',
        };
    } else {
        // If there is an previous UTM, use that:
        if (prevUTM && prevUTM.medium !== 'direct') {
            return prevUTM;
        }

        return {
            ...utm,
            source: 'direct',
            medium: 'none',
        };
    }
}
