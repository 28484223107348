/**
 * Returns true if the given brand is Google Pplay
 *
 * @param brand
 * @returns { boolean }
 */
export function brandIsGooglePlay({ brand }) {
    if (!brand) {
        return false;
    }
    return brand.slug === 'google-play';
}

/**
 * Returns true if the given list of products contains a euro product
 *
 * @param product
 * @returns { boolean }
 */
export function productIsGooglePlayEurProduct({ product }) {
    if (!product) {
        return false;
    }

    return brandIsGooglePlay({ brand: product.brand }) && product.currencyCode?.toUpperCase() === 'EUR';
}
/**
 * Returns true if the given list of products contains google-play
 *
 * @param cartItems
 * @returns { boolean }
 */
export function cartHasGooglePlayEurProduct({ cartItems }) {
    if (!cartItems) {
        return false;
    }
    return !!cartItems.find(item => !!productIsGooglePlayEurProduct({ product: item.product }));
}

// Returns true if the given product can be added to the cart
export function googlePlayRulesCanAddProduct({ cartItems, product }) {
    if (!cartItems?.length) {
        return true;
    }

    // If product is NOT google play && all other products in cart are google play do not allow add to cart
    if (
        !productIsGooglePlayEurProduct({ product }) &&
        allItemsAreOfSameBrand({ cartItems, brandSlug: 'google-play' })
    ) {
        return false;
    }

    // If product is google play && all other products in cart are not google play do not allow add to cart
    if (
        productIsGooglePlayEurProduct({ product }) &&
        !allItemsAreOfSameBrand({ cartItems, brandSlug: 'google-play' })
    ) {
        return false;
    }

    return true;
}

export function allItemsAreOfSameBrand({ cartItems, brandSlug }) {
    if (!cartItems?.length) {
        return false;
    }

    return cartItems.every(
        item => item.product.brand.slug === brandSlug && item.product.currencyCode.toUpperCase() === 'EUR'
    );
}

export function getGooglePlayExcludedPaymentMethods() {
    return ['daopay', 'onebip', 'phone', 'fortumo', 'dinrex.fortumo', 'daopaysms', 'payssion.razer'];
}

// DEV-6705: Show payment fee for certain payment methods for Google Play Eur SKUS
export const googlePlayPaymentMethodsWithFee = ['sofort', 'paypal', 'dinrex.paypal', 'bancontact'];
