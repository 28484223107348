import { mapGetters, mapActions } from 'vuex';
import * as Sqrl from 'squirrelly';

function render(content, params) {
    return Sqrl.Compile(content)(params, Sqrl);
}

export default {
    computed: {
        ...mapGetters({
            getFile: 'translation/getFile',
        }),

        importPaths() {
            return this.$options.__i18nFiles;
        },

        languages() {
            const languages = {
                en: 'English',
                nl: 'Nederlands',
                fr: 'Français',
                de: 'Deutsch',
                fi: 'Suomi',
                it: 'Italiano',
                es: 'Español',
                pt: 'Português',
                pl: 'Polski',
                tr: 'Türkçe',
                da: 'Dansk',
                sv: 'Svenska',
                nb: 'Norsk',
                cs: 'Čeština',
                el: 'Ελληνικά',
                ro: 'Română',
                ru: 'Pусский',
                sk: 'Slovaški',
                hu: 'Magyar',
                bg: 'български',
                hr: 'Hrvatski',
            };
            return languages;
        },
    },

    methods: {
        getFiles(language) {
            if (language !== this.$locale.language) {
                if (this.filesAreLoaded) return this.importPaths.map(path => this.filesByLanguage?.[language]?.[path]).filter(Boolean);
                return [];
            }
            return this.importPaths.map(path => this.getFile(path));
        },

        translate(identifier, params = {}) {
            return this.translateIn(this.$locale.language, identifier, params);
        },

        translateIn(language, identifier, params = {}) {
            const files = this.getFiles(language);

            let index = files.length - 1;

            let current = null;
            while (index >= 0) {
                const file = files[index];

                if (identifier in file) {
                    current = file[identifier];
                    break;
                }

                index -= 1;
            }

            if (typeof current === 'string') {
                // Resolve if neccesary:
                if (current.indexOf('@:') === 0) {
                    return this.translateIn(language, current.substring(2), params);
                }

                return this.translateParams(current, params);
            } else {
                return '';
            }
        },

        translateParams(content = '', params = {}) {
            // const render = template(content, {
            //     //evaluate: /\{\{#(.+?)\}\}/g,
            //     interpolate: /\{{1,2}\s*([a-zA-Z](?:.+?))\s*\}{1,2}/g
            // });

            try {
                const fixedContent = content.replace(/\{{1,2}\s*([a-zA-Z](?:.*?))\s*\}{1,2}/g, (match, param) => `{{${param}}}`);

                const result = render(fixedContent, {
                    $locale: {
                        region: this.$locale.region,
                        language: this.$locale.language,
                    },
                    ...this.$locale.globals,
                    ...params,
                });

                return result;
            } catch (e) {
                console.warn('Error in translateParams: ', content);
                console.error(e);

                return this.translateParamsSimple(content, params);
            }
        },

        translateParamsSimple(content = '', params = {}) {
            const self = this.$locale;

            function replacer(value, name) {
                return params[name] || self.globals[name];
            }

            // Replace params:
            return content.replace(/{{([a-zA-Z0-9]+)}}/g, replacer).replace(/{([a-zA-Z0-9]+)}/g, replacer);
        },

        translateGlobalParams(content = '') {
            if (content.includes('{')) {
                try {
                    const fixedContent = content.replace(/\{{1,2}\s*([a-zA-Z](?:.*?))\s*\}{1,2}/g, (match, param) => `{{${param}}}`);

                    const result = render(fixedContent, {
                        ...this.$locale.globals,
                    });

                    return result;
                } catch (e) {
                    return content;
                }
            } else return content;
        },

        mapLanguage(language) {
            let lang = language.toLowerCase();

            if (lang === 'gb') {
                lang = 'en';
            }

            return this.languages[lang];
        },
    },
};
