import { postJSON } from '../utils/json';

export default ({ dundleNextAPI }) => {
    return {
        async sendEvent(event) {
            return await postJSON(`${dundleNextAPI}/firehose/`, event, {
                'Content-Type': 'application/json',
                source: 'dundle.com',
            }).catch(error => {
                // swallow
            });
        },
    };
};
