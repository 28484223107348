import { definePlugin } from '@dundle/utils/nuxt';

export default definePlugin(({ app, store, route, env }, inject) => {
    app.$locale.publicPath = env.publicPath;

    const syncLocaleCookie = () => {
        const savedIso = app.$cookies.get('dundle.session.locale');

        const iso = app.$locale.currentLocale.iso;

        if (savedIso !== iso) {
            app.$cookies.set('dundle.session.locale', iso, {
                maxAge: 60 * 60 * 24 * 7 * 52,
                path: '/',
            });
        }
    };

    if (process.client) {
        syncLocaleCookie();

        app.$locale.watchLocale(locale => {
            store.dispatch('translation/fetchFiles');
            store.dispatch('data/fetchCategories');
            store.dispatch('data/fetchMethods');

            syncLocaleCookie();
        });
    }
});
